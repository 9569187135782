.container {
    display: flex;
    gap: 60px;
}

@media screen and (max-width: 650px) {
    .container {
        width: 55%;
        margin: 0 auto;
    }
}
