.container {
    width: 80%;
    font-family: "AktivGroteskEx_A_Bd";
    display: flex;
    gap: 16px;
}

.error {
    height: 14px;
    color: var(--error00);
    font-size: 10px;

    margin-top: 5px;

}

.containerMobile {
    display: none;
    gap: 20px;
    justify-content: space-between;
    height: 300px;

    width: 80%;


}

@media screen and (max-width: 650px) {
    .container {
        width: 100%;
    }


    .error {
        position: absolute;
        top: 80px
    }
}
