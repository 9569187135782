.simplebar-scrollbar {
    height: 30px !important;
    width: 5px !important;
    background: #5795eb;
    opacity: 0.6 !important;
    z-index: 10;
    border: none !important;
    border-radius: 10px
}

.simplebar-scrollbar::before {
    content: '';
    background: transparent !important;
}

.simplebar-vertical {

    height: 92% !important;
    top: 5px !important;
    overflow: initial;
}