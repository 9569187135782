.container {
    display: flex;
    position: relative;
    margin-bottom: 8px;
}

.text {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--secondary00);
    display: flex;
    align-items: flex-start;
    position:relative;

}

.label {
    padding-left: 24px;
    flex: 1;
}

.checkboxContainer{
    width:24px;
    height:24px;
    border: 1px solid var(--primary02);
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 650px) {
    .descriptionPanelText {
        width: 287px;
    }
}


