.container {
    height: 100%;
    position: relative;
    color: var(--secondary00);
}

.containerOverlay {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.inner {
    width: 434px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.imgContainer {
    width: 127px;
    margin: 0 auto;
}

.logo {
    width: 100%;
}

.title {
    width: 80%;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: var(--secondary002);
    margin: -20px auto 0;

}

.subTitle {
    width: 434px;
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin: 48px auto 136px;

    color: var(--secondary002);

}

.btn {
    width: 287px;
    margin: 30px auto 0;
}

.imgContainer {
    width: 287px;
    display: flex;
    flex-direction: column;
    gap: 100px;
    justify-content: center;
    align-items: center;


}

.logo {
    width: 100%;

}

.icon {
    margin-bottom: 48px;
    width: 126px;
}

@media screen and (max-width: 650px) {
    .inner {
        width: 90%;
        height: 100%;
        padding-top: 120px;
    }

    .imgContainer {
        gap: 64px;
    }

    .logo {
        width: 70%
    }

    .icon {
        width: 80px;
    }

    .subTitle {

        margin: 0 auto;
        margin-top: 24px;
        font-size: 16px;
        line-height: 22px;

    }

    .btn {
        position: absolute;
        bottom: 48px;
        left: 16px;
        width: 92%
    }
}
