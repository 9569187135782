.container {
    height: 100%;
    display: flex;
    overflow: hidden;
}

.side {
    width: 45%;
    /*position: relative;*/
    background-color: var(--primary00)
}

/*.imgContainer {*/
/*    width: 75%;*/
/*    position: absolute;*/
/*    top: 65%;*/
/*    left: 5%;*/
/*}*/

/*.logo {*/
/*    width: 100%*/
/*}*/

/*.text {*/
/*    font-family: "AktivGroteskEx_A_Bd", sans-serif;*/
/*    font-size: 32px;*/
/*    line-height: 45px;*/
/*    color: var(--secondary00);*/
/*    margin-top: 20px;*/
/*}*/

.main {
    font-family: "AktivGroteskEx_A_Rg", sans-serif;
    color: var(--secondary00);
    background: var(--primary01);
    width: 55%;
    height: 100%;
    padding: 24px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;



}

.inner {
    width: 55%;
    margin-top: 20px;
    margin-bottom: 60px;
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.innerScroll{
    width:100%;
    height:var(--app-height);
    overflow: auto;

}



/*!* Hide scrollbar for Chrome, Safari and Opera *!*/
.innerScroll::-webkit-scrollbar{
    display: none;
}

/*!* Hide scrollbar for IE, Edge and Firefox *!*/
.innerScroll{
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.title {
    font-size: 40px;
    line-height: 45px;
    padding-bottom: 8px;
    margin-top: 24px;

}

.titleMobile {
    display: none;

    font-size: 40px;
    line-height: 45px;
    margin-bottom: 8px;
}

.subTitle {
    font-size: 16px;
    line-height: 20px;
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
    flex: 0;
    /*height:18px*/
}

.subTitleLink {
    color: var(--secondary02);
    cursor: pointer

}

.subTitleLink:hover {
    color: var(--secondary03-hover);
}

.box {
    height: 80px
}


.error {
    width: 100%;
    height: 14px;
    color: var(--error00);
    font-size: 10px;
    text-align: left;
    padding: 33px 0;
}

@media screen and (max-width: 1100px) {
    .side{
        width:25%
    }

    .main{
        width:75%
    }
}

@media screen and (max-width: 650px) {
    .side {
        display: none;
    }

    .main {
        width: 100%;
        background-image: url("../../../common/images/sideAuthImg.png");
        background-size: cover;

    }

    .inner{
        width: calc(100% - 32px) ;
        margin-top: 0;
        margin-bottom: 0;

    }
    .innerScroll{
        width:100%
    }

    .form {
        /*height: 90vh;*/
        height:100%;
        /*overflow-y: scroll;*/



    }

    .form2 {
        /*height: 90vh;*/
        height:100%;
        overflow-y: hidden;
        padding: 16px 0;

    }


    .title {
        display: none;
    }

    .titleMobile {
        display: block;
        /*margin-top: 16px;*/
        font-size: 24px;
        line-height: 34px;
        margin-top: 24px;
    }

    .subTitle {
        font-size: 12px;
        line-height: 17px;
        /*margin-bottom: 48px;*/
    }

    .box {
        height: 48px
    }

    .error {
        /*position: absolute;*/
        /*bottom: 32px;*/
        /*left: 16px;*/
        width: 92%;
        padding: 16px 0;
    }
}

@media screen and (max-height: 720px) {
    .inner {
        margin-top: 0;
        margin-bottom: 0;
    }
}
