
.container {
    flex: 1;
    height: 46px;
    width: 80%;
    padding: 8px;
    background: var(--primary01);
    box-shadow: 2px 2px 4px var(--shadow01), 2px 2px 4px var(--shadow02);
    border-radius: 10px;
    position: relative;
}

.stepper {
    display: none;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--secondary00);
    position: relative;

}

.iconContainer {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: -10px;
    left: -10px;
}

.iconContainer:active {
    background-color: var(--primary02);
}

.icon {
    margin-left: 2px;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.title {
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 9px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--secondary01);
    opacity: 0.6;
}

.titleMobile {
    display: none;
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 9px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--secondary01);
    opacity: 0.6;
}

.bar {
    width: 100%;
    height: 12px;
    margin-top: 4px;
    background-color: var(--primary02);
    border-radius: 5px;
    position: relative;

}

.inner {
    width: 100%;
    height: 100%;
    padding: 4px 8px;

}

.fill {
    height: 4px;
    background: var(--secondary02);
    border-radius: 3px;
}

.pointers {
    display: none
}

.pointer {
    height: 15.29px;
    border-right: 1.5px solid var(--primary00);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.point__q1 {
    width: calc(1 / 19 * 100%);
}

.point__q2 {
    width: calc(2 / 19 * 100%);
}

.point__q3 {
    width: calc(3 / 19 * 100%);
}

.point__q4 {
    width: calc(4 / 19 * 100%);
}

.point__q5 {
    width: calc(5 / 19 * 100%);
}

.point__q6 {
    width: calc(6 / 19 * 100%);
}

.point__q7 {
    width: calc(7 / 19 * 100%);
}

.point__q8 {
    width: calc(8 / 19 * 100%);
}

.point__q9 {
    width: calc(9 / 19 * 100%);
}

.point__q10 {
    width: calc(10 / 19 * 100%);
}

.point__q11 {
    width: calc(11 / 19 * 100%);
}

.point__q12 {
    width: calc(12 / 19 * 100%);
}

.point__q13 {
    width: calc(13 / 19 * 100%);
}

.point__q14 {
    width: calc(14 / 19 * 100%);
}

.point__q15 {
    width: calc(15 / 19 * 100%);
}

.point__q16 {
    width: calc(16 / 19 * 100%);
}

.point__q17 {
    width: calc(17 / 19 * 100%);
}

.point__q18 {
    width: calc(18 / 19 * 100%);
}

/*Styles for description panel*/
.descriptionPanelContainer {
    margin-top: -115px;
    position: relative;
}

.descriptionPanel {
    position: absolute;
    top: 47px;
    left: 97px;
}

.descriptionPanelText {
    width: 290px;
    padding: 16px;
    background: var(--primary01);
    box-shadow: 0 4px 12px var(--shadow03);
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary00);

    position: absolute;
    top: -13px;
    left: -5px;
}

.text1 {
    font-family: "AktivGroteskEx_A_Bd", serif;
}

.text2 {
    font-family: "AktivGroteskEx_A_Md", serif;
}

@media screen and (max-width: 650px) {
    .container {
        background: transparent;
        box-shadow: none;
        padding: 0;
        width: 100%;
        height: auto;

    }

    .header {
        flex: 1;
    }

    .stepper {
        display: flex;
        align-items: flex-start;
    }

    .title {
        display: none
    }

    .titleMobile {
        display: block;
        text-align: center;
        font-size: 12px;
        line-height: 17px;
        margin-top: 7px;
    }

    .bar {
        margin-top: 14px;
        height: 2px;
        border-radius: 0;

    }

    .inner {
        padding: 0;
    }

    .fill {
        height: 100%;
        border-radius: 0;
    }

    .pointers {
        display: block;
    }

    .descriptionPanelContainer {
        display: none
    }
}
