.title{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color:var(--secondary00);
}

.list{
    border:1px solid var(--primary02);
    padding:16px 16px 8px 16px;
    border-radius: 8px;
    margin: 8px auto;
}

.listItem{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color:var(--secondary00);
    display: flex;
    gap:16px;
    align-items: center;
    margin-bottom: 4px;

}

