.container {
    display: flex;
    flex-direction: column;
    position: relative;


}

.input:focus {
    outline: none;
    border: 1px solid var(--secondary02);
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

/*!*!* Chrome, Firefox, Opera, Safari 10.1+ *!*!*/
/*!*.input::placeholder {*!*/
/*!*    color: var(--primary03);*!*/
/*!*    opacity: 1; !* Firefox *!*!*/
/*!*}*!*/

/*!*!* Internet Explorer 10-11 *!*!*/
/*!*.input:-ms-input-placeholder {*!*/
/*!*    color: var(--primary03);*!*/
/*!*}*!*/

/*!*!* Microsoft Edge *!*!*/
/*!*.input::-ms-input-placeholder {*!*/
/*!*    color: var(--primary03);*!*/
/*!*}*!*/

input:focus ~ .floatingLabel{
    top: 8px;
    left: 16px;
    font-size: 12px;
    line-height: 16px;
    color:var(--secondary02);
    opacity: 1;
}

input:not(:focus):valid ~ .floatingLabel{
    top: 8px;

    left: 16px;
    font-size: 12px;
    line-height: 16px;
    color:var(--primary03);
    opacity: 1;
}

.input {
        height: 56px;
        background: var(--primary02);
        box-shadow: 2px 4px 4px var(--shadow04);
        border-radius: 8px;
        border: none;
        font-family: "AktivGroteskEx_A_Md", sans-serif;
        font-size: 16px;
        line-height: 20px;
        padding: 16px 16px 0;
        color: var(--secondary00);
        caret-color: var(--secondary02);
}

.floatingLabel {
    position: absolute;
    pointer-events: none;
        font-size: 18px;
        line-height: 23px;
    color: var(--primary03);
    left: 16px;
    top: 16px;
    transition: 0.2s ease all;
}

.icon {
    cursor: pointer;
    position: absolute;
    top: 21px;
    right: 16px;

}

.error {
    height: 14px;
    color: var(--error00);
    font-size: 10px
}
