.chart {
  padding-top: 0;
  padding-left: 7px;
}

.inner {
  width: 95%;
  margin: 0 auto;
  list-style: none;
  display: flex;
  flex-direction: column;
}

.sub {
  margin-top: 8px;
  flex: 1;
}

.card_container {
  margin-top: 0;
}

.suggestion_tables_title {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 18.6013px;
  line-height: 28px;
  width: 93%;
  margin: 0 auto;
}

.suggestion_tables {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.imgMetricPageChart {
  padding-top: 5px
}
