.btn {
    width: 182px;
    height: 152px;
    background-color: var(--primary01);
    border-radius: 10px;
    text-align: center;
    padding: 32px 10px;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 18px;
    line-height: 32px;
    color: var(--secondary00);
    position: relative
}

.btnSelected {
    background-color: var(--secondary04);
    border: 1px solid var(--secondary03);
    box-shadow: 2px 2px 4px var(--shadow01), 2px 2px 4px var(--shadow02);
}

.btnChecked {
    position: absolute;
    top: 10%;
    right: 10%;
    width: 16px;
}

.iconContainer {
    width: 100%;
    align-items: center;
    margin-bottom: 5px;
}

.btnImgMale {
    height: 49px;
    width: 49px
}

.btnImgFemale {
    height: 49px;
    width: 37px
}

.btnInput {
    width: 1px;
    height: 1px;
    appearance: none;
}

.btn:hover {
    border: 1px solid var(--secondary03);
    box-shadow: 2px 2px 4px var(--shadow01), 2px 2px 4px var(--shadow02);
}

.symbol {
    font-size: 28px;
    line-height: 32px;
    padding-top: 10px;
}

@media screen and (max-width: 650px) {
    .btn {
        width: 50%
    }
}
