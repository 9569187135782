.loaderContainer {

    width: 300px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loaderTitle {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 22px;
    color: var(--primary03);
}