.container{
    display: flex;
    height:calc(100% - 147px);
    flex-direction: column;
    justify-content: space-between;
}

.inner {
    display: flex;
    flex-direction: column;
    gap: 2px;
}


.forgetPassword {
    font-size: 12px;
    line-height: 25px;
    color: var(--secondary02);
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
    margin-top: -8px;
}

.forgetPassword:hover {
    color: var(--secondary03-hover);
}

.inputLocked{
    position: relative;
}

.label {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color:var(--primary03);
    position:absolute;
    top:8px;
    left:16px;
}

.input {
    height: 56px;
    background: var(--primary001);
    box-shadow: 2px 4px 4px var(--shadow04);
    border-radius: 8px;
    border: none;

    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 16px;
    line-height: 22px;
    padding: 16px 18px 0;
    color: var(--primary002);
    margin-bottom: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center
}
.icon{
    position:absolute;
    right:16px;
    top:21px
}

.bottom {
    width: 100%;
    margin: 0 auto 32px;
}


@media screen and (max-width: 650px) {
    .bottom {
        margin-bottom: 16px;
    }

}


