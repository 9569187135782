.sub {
  margin: 0px 10px;
  /* margin-top: 7.52px; */
  flex: 1;
}
.inner {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.title {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 13.3458px;
  line-height: 26px;
  margin-bottom: 0;


}

.titleRestore {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 16px;
  line-height: 20px;
  align-self: center;
}

.subtitle {
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 12px;
  line-height: 26px;
  margin-bottom: 0;
}

.subText {
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 9.35194px;
  line-height: 15px;
  margin-bottom: 5px;
}

.icon {
  width: 9px;
}

.iconRestore{
    width: 32px;
  height: 32px;
}

.icon img, .iconRestore img{
  width: 100% !important
}

.listItem{
  display: flex;
  flex-direction: row;
  gap:6px;
  align-items: flex-start;
}

.point{
  width:3px ;
  height:3px;
  border-radius: 50%;
  margin-top:6px;

}
