.container {
    position: absolute;
    bottom: 40px;
    left: 0px;
    width: 100%;
    /*height: 10%;*/
    margin: 0 auto;
    /*padding: 0 40px*/
}

.inner {
    max-width: 80%;
    margin: 10px auto;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.innerMobile {
    display: none;
}


.bottom {
    display: none;
}

@media screen and (max-width: 650px) {
    .container {
        padding: 0;
        width: 100%;
        height: auto;
        position: initial;
    }

    .inner {
        display: none;

        max-width: 100%;
    }


    .innerMobile {
        display: block;
        width: 100%;
    }

    .top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .bottom {
        display: block;
        position: absolute;
        bottom: 16px;
        left: 16px;
        width: 92%
    }
}

