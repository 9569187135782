.container{
    width:98%;
    margin-left:16px;
    padding-top:15px;
    height:280px;
}

.title{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 18px;
}

.inner{
    display:flex;
    gap:16px;
    margin: 6px auto;

}

.imgContainer{
    position:relative;
    width:121px;
    height:101px;
}

.image{
    width:121px;
    height:101px;
    border-radius: 10px;
}

.iconContainer{
    width:44px;
    height:44px;
    border-radius: 50%;
    background-color: var(--primary50);
    position:absolute;
    bottom: 5px;
    left:5px;
    display: flex;
}

.icon{
    margin:0 auto
}

.info{

    width:560px
}

.rowItems {
    font-size: 14px;
    padding: 2px;
    line-height: 13px;
    flex-direction: column;
    flex: 1;
}

.rowItem {
    display: flex;
    align-items: center;
    list-style: none;
    padding: 0 12px;
}

/*.rowItem:last-child {*/
/*    border-bottom: none;*/
/*}*/

.cellItem {
    display: flex;
    flex:1;
    /*justify-content: center;*/
    align-items: center;
    gap: 10px;
    list-style: none;
}

.cell {
    flex: 1;
    text-align: center;
}

.tabs {

    margin-top: 13px;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

.tab {

}
.tabTitle {
    width: 35%;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 14px;
    line-height: 18px;

    background-color: var(--primary50);
    padding: 6px 11px;
    text-align: center;
    border-radius: 7.2px 7.2px 1.8px 1.8px;
    margin-bottom: 6px;
}
.tabItems{
    display: flex;
flex-wrap: wrap;
    row-gap:5px;
    column-gap: 20px;
}
.description {
    display: flex;
    align-items: center;
    gap: 3px;

}
.listIcon {
    width: 18.23px;

}

.listText {
    font-size: 10px;
    line-height: 18px;
}
