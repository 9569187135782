.container {
    width: 91px;
    height: 64px;
}

.monthContainer {
    width: 150px;
}

.inputContainer {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.input {
    width: 100%;
    height: 100%;
    background: var(--primary02);
    border: 1px solid var(--primary02);
    box-shadow: 2px 4px 4px var(--shadow04);
    border-radius: 8px;
    cursor: pointer;
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    padding-right: 20px;
    font-size: 16px;
    line-height: 16px;
    color: var(--primary03);
    text-align: center;
}

.inputOk {
    color: var(--secondary00);
}

.input:hover {
    border: 1px solid var(--primary02);
}

.input:focus {
    outline: none;
    border: 1px solid var(--secondary02);
}

.icon {
    width: 16px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

.mobile {
    display: none
}

.panel {

    background: var(--primary02);
    box-shadow: 2px 4px 4px var(--shadow05),
    2px 2px 4px var(--shadow06);
    border-radius: 10px;
    padding: 16px;
    margin-top: 16px;
    display: grid;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.panelGridDay {
    width: 316px;
    height: 188px;
    grid-template-columns: repeat(7, 1fr);
}

.panelGridMonth {
    width: 316px;
    height: 188px;
    grid-template-columns: repeat(3, 1fr);
}

.panelYear {
    width: 120px;
    padding-bottom: 0;
    text-align: justify;
}

.panelMonth {
    width: 40%;
    padding-bottom: 0;
    text-align: justify;
}

.selections {
    flex: 1;
    background: transparent;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    border: none;
    font-size: 14px;
    line-height: 12px;
    color: var(--secondary00);
    text-align: center;
    cursor: pointer;
}

.year {
    height: 30px;
}

.selections:hover {
    font-size: 18px;
}

@media screen and (max-width: 650px) {
    .container {
        width: 25%;
    }

    .monthContainer {
        width: 40%;
    }

    .web {
        display: none;
    }

    .mobile {
        display: block;
    }

    .panelYear {
        width: 25%
    }

}


