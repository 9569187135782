.btnContainer {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 22px;
  color: var(--primary800);
  background-color: var(--primary100-opacity1);
  border-radius: 15px;
  border: 2px solid var(--primary900);
  box-shadow: 1px 3px 5px var(--primary950);
  height: 100px;
  cursor: pointer;

  padding: 20px 20px;
  position: fixed;
  top: 20px;
  right: 20px;

  z-index: 1000;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

.inner:hover,
.inner:active {
  opacity: 0.5;
}


