.main {
  width: 100%;
  margin: 0 auto;
  /*padding: 0 15px;*/
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.main li {
  /*margin-bottom: 13px;*/
}
