.header {
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 13.3784px;
  padding-left: 10px;
  padding-bottom: 4px;
  display: flex;
  gap: 5px;
}

.bold {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  height: 30px;
  background-color: var(--primary50);
  border-radius: 58px;
padding: 0 10px;
  display: flex;

}

.inner {
  width: 100%;
  height: 15px;
  border-radius: 25px;
  align-self: center;
    position: relative;
}

.fillInnerRisk {
  width: 50%;
  height: 100%;
  border-radius: 25.8012px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.noActive {
  opacity: 0.2;
}

.balancePoint {
  width: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.labels {
  width: 100%;
  padding: 0 10px;
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  display: flex;
}

.riskLabel {
  flex: 1;
  text-align: center;
}
