.container {
    height: 100%;
    background-color: var(--primary00);
    position: relative;
    color: var(--secondary00);

    /*background-image: url("../../../common/images/backgroundImg.png");*/
    /*background-position: center;*/
    /*background-size: cover;*/

}

.containerOverlay {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.main {
    width: 100%;
    background-image: url("../../../common/images/sideAuthImg.png");
    background-size: cover;
    /*height: 100vh;*/
}

.inner {
    width: 657px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.imgContainer {
    width: 157px;
    margin: 0 auto;
}

.logo {
    width: 100%

}

.title {
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 32px;
    line-height: 45px;
    text-align: center;
    color: var(--secondary00);
    margin-top: 34px;
}

.subTitle {
    font-family: "AktivGroteskEx_A_Rg", sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    margin-top: 34px;
}

.btn {
    width: 150px;
    margin: 30px auto 0;

}

.btnMobile {
    display: none;
}

.subtitle2 {
    font-family: "AktivGroteskEx_A_Rg", sans-serif;
    font-size: 12px;
    line-height: 26px;
    color: var(--primary03);
    text-align: center;

}


@media screen and (max-width: 650px) {

    .container {
        width: 100%;
        background-image: url("../../../common/images/sideAuthImg.png");
        position: relative;

    }

    .inner {
        width: 92%;
        flex: 1;
        top: 40%;
        transform: translate(-50%, 0);
        position: relative;
        overflow: hidden;
    }

    .imgContainer {
        width: 50%;
        margin: 0;

    }


    .title {
        font-size: 20px;
        line-height: 28px;
        text-align: left;
        margin-top: 24px;
    }

    .subTitle {
        width: 90%;
        line-height: 22px;
        text-align: left;
        margin-top: 40px;
        color: var(--secondary002)
    }

    .btn {
        display: none;
    }


    .btnMobile {
        display: block;
        position: absolute;
        bottom: 16px;
        left: 16px;
        margin-top: 64px;
        width: 92%
    }

    .subtitle2 {
        margin-top: 8px;
        margin-left: 8px;
        text-align: left;
        line-height: 17px;
        color: var(--secondary002)
    }
}

