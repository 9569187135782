.container {
    position: absolute;
    bottom: 40px;
    left: 0px;
    width: 100%;
    /*height: 10%;*/
    margin: 0 auto;
    /*padding: 0 40px*/
}

.inner {
    max-width: 80%;
    margin: 10px auto;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.innerMobile {
    display: none;
}


.bottom {
    display: none;
}

.stepper {
    display: none;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--secondary00);
    position: relative;

}




.iconContainer {
    width: 40px;
    height: 40px;
    background-color: transparent;
    border-radius: 50%;
    border: none;
    position: absolute;
    top: -10px;
    left: -10px;
}

.icon {
    margin-left: 2px;
    position: absolute;
    top: 50%;
    left: 40%;
    transform: translate(-50%, -50%);
}

.header {
    flex: 1;
}

.titleMobile {
    display: none;
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 9px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--secondary01);
    opacity: 0.6;
}



@media screen and (max-width: 650px) {
    .container {
        padding: 0;
        width: 100%;
        height: auto;
        position: initial;
    }

    .inner {
        display: none;

        max-width: 100%;
    }


    .innerMobile {
        display: block;
        width: 100%;
    }

    .top {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .bottom {
        display: block;
        position: absolute;
        bottom: 16px;
        left: 16px;
        width: 92%
    }

    .stepper {
        display: flex;
        align-items: flex-start;
    }



    .titleMobile {
        display: block;
        text-align: center;
        font-size: 12px;
        line-height: 17px;
        margin-top: 7px;
    }
}

