.container {
    height: var(--app-height);
    width: 100vw;
    display: flex;
    overflow: hidden;
}

.side {
    width:35%
}

.main {
    flex: 1;
    background-color: var(--primary00);
    display: flex;
    flex-direction: column;

}

.header {
    width: 100%;
    height: 15%;
    padding-top: 40px;
    padding-right: 56px;

}

.logo {
    width: 55px;
    float: right;
}

.content {
    width: 100%;
    flex: 1;
    padding: 10px 20px;
    position: relative;
}

@media screen and (max-width: 1100px) {
    .side{
        width:20%
    }
}

@media screen and (max-width: 650px) {

    .container {
        width: 100vw;
    }

    .content {
        width: 100%;
    }

    .side {
        display: none
    }

    .header {
        display: none;
    }
}



