.container{
    display: flex;
    width: calc(100% + 40px);
    margin-left: -20px;
}

.mainContainer{
    background-color: var(--primary50);
    padding: 16px;
    flex:2.4;
    position: relative;
}

.sideContainer{
    background-color: #E8F3F6;
    flex:1
}

.title{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 16px;
    color:#002C42;
    margin-bottom: 4px;
}

.subTitle{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 13px;
    color:var(--primary04)
}

.rowItems {
    font-size: 10px;
    padding: 2px;
    line-height: 13px;
    flex-direction: column;
    margin-top: 16px;
    flex: 1;
}

.rowItem {
    display: flex;
    align-items: center;
    border-bottom: 3px solid var(--primary100);
    list-style: none;
    font-family: "AktivGroteskEx_A_xBd", sans-serif;
    font-size: 14px;
    height:50px;
}

.rowItem:last-child {
    border-bottom: none;
}

.cellItem {
    display: flex;
    flex: 1;
    height: 100%;
    list-style: none;

}

.cell {
    flex: 1;
    text-align: center;
    border-right: 3px solid var(--primary100);

}


.cell:first-child, .cell:last-child {
    border-right: 3px solid transparent;
}

.notesText{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 12px;
    color: var(--primary002);
    margin-top: 8px;
}

.topSideContainer{
    background-color: #002C42;
    padding: 16px 16px 48px 24px;
    font-family: "AktivGroteskEx_A_Rg", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--primary100);
    border-bottom-left-radius: 20px;
    display: flex;
    flex-direction: column;

}

.sideContainerText{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 12px;

    color: #002C42;
    padding-left: 32px;
}

.sideContainerTextVersion{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 6px;
    color: var(--primary04);
    padding-right: 16px;
    text-align: right;
}

.imgContainer{
    width: 70px;
    font-size: 7px;
    line-height: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2px;
    color:var(--primary02);
    position: absolute;
    left: 16px;
    top: 0;
}
