.btn {
    width: 76px;
    height: 44px;
    padding: 6px 12px;
    background: var(--secondary03);
    border: none;
    cursor: pointer;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-family: "AktivGroteskEx_A_Bd", serif;
    color: var(--primary000)
}

.btnOutline {
    background: transparent;
    border: 1px solid var(--secondary02);
    color: var(--secondary02)
}

.btnOutline:hover {
    border: 1px solid var(--secondary03-hover);
    color: var(--secondary03-hover)
}

.btnOutline:active {
    background: var(--secondary05);
    border: 1px solid var(--secondary02);
    color: var(--secondary02)
}

@media screen and (max-width: 650px) {
    /*.btn {*/
    /*    position: absolute;*/
    /*    top: 90%;*/
    /*}*/
}


