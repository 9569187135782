.container {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    width: 100%;
    height: 100%;

}

.inner {
    width: 90%;
    margin-left: 30px;
    height: calc(var(--app-height) - 250px);
    /*height: calc(100% - 100px);*/
    overflow-y: scroll;
    /*animation-duration: 3s;*/
    /*animation-name: fadein;*/

    /*animation-direction: alternate;*/
}


/* Hide scrollbar for Chrome, Safari and Opera */
.inner::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.inner {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.footerMobile {
    display: none
}

.stepper {
    font-size: 16px;
    line-height: 32px;
    color: var(--primary03);
}

.label {
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 22px;
    line-height: 32px;
    border-left: 2px solid var(--secondary00);
    padding: 0 16px;
    color: var(--secondary00);
    margin: 10px 0 5px 0
}

.options {
    display: flex;
    /*height: calc(var(--app-height) - 120px);*/
    flex-direction: column;
    gap: 16px;
    margin-top: 30px;

}

.footerWeb{

}





@media screen and (max-width: 650px) {
    .inner {
        width: 100%;
        margin:0 auto;
        /*height:auto*/
        height: calc(var(--app-height) *0.88);
        /*height: calc(100% - 70px);*/
    }

    .footerWeb {
        display: none;
    }

    .footerMobile {
        display: block;
        margin-top: 8px;
    }

    .stepper {
        display: none
    }

    .label {
        margin-top: 24px;
        padding: 0;
        width: 100%;
        font-size: 20px;
        line-height: 28px;
        border-left: none;
        margin-bottom: 0;
    }

    .options {
        margin-top: 36px;
        position: relative;
        gap: 10px
    }
}

