
.descriptionPanelContainer {
    width:10px;
    padding-top: 8px;
    margin-left: 5px;
    position: relative;
}

.descriptionPanel {

    position: absolute;
    top: -13px;
    left: -1px;
    z-index: 20;
}




@media screen and (max-width: 650px) {

    .descriptionPanel {
        position: absolute;
        top: -13px;
        left: -1px;
        z-index: 20;
    }
}
