.container {
    height: var(--app-height);
    position: relative;
    color: var(--secondary00);
}

.containerOverlay {
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.70);
}

.inner {
    width: 613px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}


.imgContainer {
    width: 187px;
    margin: 0 auto;

}

.logo {
    width: 100%;
}

.title {
    width: 80%;
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    color: var(--secondary002);
    margin: -20px auto 0;

}

.subTitle {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    margin: 40px auto 0;
    padding:16px 0;
    color: var(--secondary002);

}

.btnIcons {
    width: 70%;
    margin: 30px auto 0;
    display: flex;
    justify-content: space-between;


}

.btnMobile {
    display: none;
}

.subtitle2 {
    font-family: "AktivGroteskEx_A_Rg", sans-serif;
    font-size: 12px;
    line-height: 26px;
    color: var(--primary03);
    text-align: center;

}


.bottom {
    font-family: "AktivGroteskEx_A_Rg", sans-serif;
    font-size: 40px;
    line-height: 34px;
    width: 414px;
    margin: 103px auto 0;
}

.btn {
    width: 440px;
    margin: 40px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.img {
    width: 204px;
    cursor: pointer;
}

@media screen and (max-width: 650px) {
    .inner {
        width: 100%;
    }

    .imgContainer {
        gap: 64px;
    }

    .logo {
        width: 100%
    }

    .title {
        width: 92%;
        font-size: 20px;
        line-height: 28px;
    }

    .subTitle {
        width: 80%;
        margin: 16px auto 151px;
        font-size: 16px;
        line-height: 22px;
    }

    .bottom {
        width: 100%;
        text-align: center;
        font-size: 24px;
        line-height: 34px;

    }

    .btnIcons {
        width: 80%;
        margin: 24px auto 0;
        gap: 16px;
    }

    .img {
        width: 100%
    }

    .btn {
        display: none;
    }


    .btnMobile {
        display: block;
        position: absolute;
        bottom: 16px;
        left: 16px;
        margin-top: 64px;
        width: 92%
    }

    .subtitle2 {
        margin-top: 8px;
        margin-left: 8px;
        text-align: left;
        line-height: 17px;
        color: var(--secondary002)
    }

}

