.container {
    width: 60px;
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.text {
    font-size: 18px;
    line-height: 16px;
    color: var(--secondary00);
    display: flex;
    gap: 15px;
    align-items: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}

.input {
    width: 64px;
    height: 64px;
    background: var(--primary02);
    border: 1px solid var(--primary02);
    box-shadow: 2px 4px 4px var(--shadow04);
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    line-height: 16px;
    color: var(--primary03);
    text-align: center;
    caret-color: var(--secondary03-active);
}

.inputOk {
    color: var(--secondary00);
    border: 1px solid transparent;
}

.input:hover {
    border: 2px solid var(--primary02);
}

.input:focus {
    outline: none;
    border: 1px solid var(--secondary02);
}

.icon {
    cursor: pointer;
    margin-left: 25px;
}

