.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 10px 32px;
}

.headerTitle{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 24px;
    line-height: 27px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
}

.headerSubTitle{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
}

.section{
    padding: 0 10px;
}

.sectionTitle{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
    margin-bottom: 16px;
}

.sectionTitleV2{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 14px;
    line-height: 22px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
    margin-bottom: 16px;
}

.cardContainer{
    flex:1;
    padding:12px;
    border-radius: 8px;
    background-color: var(--LIGHT_NEUTRAL_BG_MAIN);
    border: 1px solid var(--LIGHT_NEUTRAL_BORDER_WEAK);
    display: flex;
    gap: 16px;
}

.listContainer{
flex:1
}

.listTitle{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
    margin-bottom: 8px;
}

.listInner{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-left:18px
}

.listItem{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);

}

.tableWrapper{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: var(--LIGHT_NEUTRAL_BG_MAIN);
    border: 1px solid var(--LIGHT_NEUTRAL_BORDER_WEAK);
}

.rowWrapper{
    width:100%;
    display: flex;
    gap: 16px;
    padding: 12px 12px 0;
    border-bottom: 3px solid #F2F4F7;
}

.rowWrapper:last-child{
    border:none;
    padding: 12px;
}

.cell{
    flex:1;
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
}

.pageTitle{
    font-family: "AktivGroteskEx_A_Bd", sans-serif;
    font-size: 25px;
    line-height: 27px;
    padding: 32px 10px 32px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
}

.containerPerExercise{
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 12px;
    line-height: 17px;
    color: var(--LIGHT_NEUTRAL_TEXT_DEFAULT);
    background-color: #aaa;
    padding:4px 8px;
    border-radius: 8px;
}


