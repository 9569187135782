
.container {
    flex: 1;
    height: 46px;
    width: 80%;
    padding: 8px;
    background: var(--primary01);
    box-shadow: 2px 2px 4px var(--shadow01), 2px 2px 4px var(--shadow02);
    border-radius: 10px;
    position: relative;
}

/*Styles for description panel*/
.descriptionPanelContainer {
    margin-top: -115px;
    position: relative;
}

.descriptionPanel {
    position: absolute;
    top: 47px;
    left: 97px;
}

.descriptionPanelText {
    width: 290px;
    padding: 16px;
    background: var(--primary01);
    box-shadow: 0 4px 12px var(--shadow03);
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary00);

    position: absolute;
    top: -13px;
    left: -5px;
}

.text1 {
    font-family: "AktivGroteskEx_A_Bd", serif;
}

.text2 {
    font-family: "AktivGroteskEx_A_Md", serif;
}

.title {
    font-family: "AktivGroteskEx_A_Bd", serif;
    font-size: 9px;
    line-height: 14px;
    text-transform: uppercase;
    color: var(--secondary01);
    opacity: 0.6;
}

.bar {
    width: 100%;
    height: 12px;
    margin-top: 4px;
    background-color: var(--primary02);
    border-radius: 5px;
    position: relative;

}

.inner {
    width: 100%;
    height: 100%;
    padding: 4px 8px;

}

.fill {
    height: 4px;
    background: var(--secondary02);
    border-radius: 3px;
}

.pointers {
    display: none
}

.pointer {
    height: 15.29px;
    border-right: 1.5px solid var(--primary00);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.point__q {
    --i: 1; /* Default value */
    width: calc(var(--i) / 33 * 100%);
}


@media screen and (max-width: 650px) {
    .container {
        background: transparent;
        box-shadow: none;
        padding: 0;
        width: 100%;
        height:16px
    }


    .descriptionPanelContainer {
        display: none
    }


    .title {
        display: none
    }



    .bar {
        margin-top: 14px;
        height: 2px;
        border-radius: 0;

    }

    .inner {
        padding: 0;
    }

    .fill {
        height: 100%;
        border-radius: 0;
    }

    .pointers {
        display: block;
    }


}
