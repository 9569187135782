.container {
  position: relative;
  padding: 5px;
  color: white;
  z-index: 10;
}

.imgContainer {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.innerContainer {
  margin: 0 auto;
}

.header {
  width: 92%;
  padding-top: 5px;
  margin: 0 18px;
  z-index: 10;
  border-bottom: 0.981037px solid var(--primary1000);

  display: flex;
  gap: 15px;
  align-items: baseline;
}

.title1 {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
  font-size: 15px;
  /*line-height: 38px;*/
  z-index: 10;
}

.title2 {
  font-size: 12px;
  /*line-height: 36px;*/
  z-index: 10;
}

.listItems {
  list-style: none;
  height: 150px;
  display: flex;
  gap: 0px;
}

.sub {
  flex: 1;
  z-index: 10;
}
