.container {
    display: flex;
    position: relative;
    margin-bottom: 8px;
align-items: center;

}

.text {
    font-family: "AktivGroteskEx_A_Md", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--secondary00);
    display: flex;
    align-items: flex-start;
    position:relative;
}

.label {
    padding-left: 24px;
    flex: 1;
}

.description {
    font-size: 16px;
    color: var(--primary03);
    padding-left: 10px;
}

.infoIcon {
    margin-left: 10px;
}

/*Custom input element*/
.dot {
    appearance: none;
    /*background-color: var(--primary02);*/
    cursor: pointer;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-top: 2px;
    border: 1px solid var(--primary02);
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
}

.dot::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    /*box-shadow: inset 1em 1em var(--secondary03);*/
    background-color: var(--primary00);
}

.dot:checked{
    background-color: var(--secondary03);
    border: 1px solid var(--secondary03);
}

.dot:checked::before {
    transform: scale(1);
    border: max(1px, 0.05em) solid var(--secondary03);

}

.dot:focus {
    border: max(1px, 0.05em) solid var(--secondary03);

}

.descriptionPanelText {
    width: 440px;
    margin-left: 12px;
    padding: 8px 16px;
    background: var(--primary02);
    box-shadow: 0 4px 12px var(--shadow03);
    border-radius: 8px;
    font-size: 11px;
    line-height: 22px;
    color: var(--secondary00);
    position: absolute;
    bottom: 32px;
    left: 50px;
    z-index: 20;
}


@media screen and (max-width: 650px) {
    .descriptionPanelText {
        width: 287px;
    }
}


