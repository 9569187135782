.header {
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 13.3784px;
  padding-left: 10px;
  padding-bottom: 4px;
  display: flex;
  gap: 5px;
}

.bold {
  font-family: "AktivGroteskEx_A_Bd", sans-serif;
}

.inner {
  position: relative;
  width: 100%;
  height: 26px;
  background-color: var(--primary50);
  border-radius: 58px;
  display: flex;

}

.container {
  width: 100%;
  height: 13px;
  border-radius: 25px;
  align-self: center;
  margin: 0 10px ;
  position: relative;
}

.fill {
  background-color: var(--primary800);
  width: 100%;
  height: 100%;
  border-radius: 26px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.excellent {
  background-color: var(--secondary12);
}
.good {
  background-color: var(--secondary07);
}

.neutral {
  background-color: var(--secondary20);
}

.limitations {
  background-color: var(--secondary50);
}
.severeLimitation {
  background-color: var(--secondary40);
}

.pointer {
  height: 15.29px;
  border-right: 1px solid var(--primary50);
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.point__q1 {
  width: 20%;
}

.point__q2 {
  width: 40%;
  opacity: 0.7;

}

.point__q3 {
  width: 60%;
}
.point__q4 {
  width: 80%;
}

.labelsContainer{
  width: 100%;
  margin-top: 2px;
}

.labels {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

}

.label {
  width: 100%;
  font-family: "AktivGroteskEx_A_Rg", sans-serif;
  font-size: 7px;
  color: var(--primary600);
  list-style: none;
  text-align: center;
  line-height: 10px;
}

.iconContainer {
  width: 714px;
}

.iconInner {
  width: 100px;
  margin: 0 auto;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconLabel {
  font-family: "AktivGroteskEx_A_Md", sans-serif;
  font-size: 9.4642px;
  line-height: 16px;
  text-align: center;
}

.iconImg {
  width: 40px;
  height: 40px;
}
