.subTitle {
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  font-size: 20px;
  line-height: 27.87px;
  color: var(--primary1000);
  padding: 0 17px;
  margin-top: 10px;
}

.title {
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  font-size: 13.3784px;
  line-height: 14px;
}


.titleStress{
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  font-size: 13.3784px;
  line-height: 14px;
  margin-left:10px;
}

.imgStressBar{
  margin-top:24px;
  margin-left:16px
}

.container{
  position: relative;
}

.metricContainer{

  flex:1;
}

.organSystemContainer{
    width:182px;
  position: absolute;
  top: 0;
  left:16px;
  padding:20px 6px 60px;
  border:3px solid;
  border-radius:17px
}

.organSystemContainerArrow{
    position: absolute;
    top: 8px;
    right:0;
  transform: translateX(100%);

}

.sectionContainer{
  padding: 9px 9px 9px 215px ;
  border-radius: 17px 0 17px 17px;
  background: #E9E9E9;
  margin-top:8px
}

.organSystemContainerLabel{
  font-family: "AktivGroteskEx_A_Bd",sans-serif;
  color: var(--primary1000);
  font-size: 13px;
  line-height: 14px;
  position: absolute;
  top:0;
  left:52%;
    transform: translateX(-50%);
}

.organSystemContainerDot{
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: var(--primary400);
}
